<template>
  <a-row justify="center">
    <a-col span="20">
      <interview-question-bank-page :page-size="8" />
      <div class="interviewQuestionListHome">
        <a-space>
          <div style="font-size: 24px; font-weight: bold">面试题目</div>
          <a-button type="text" @click="addVisible=true">
            添加题目
          </a-button>
        </a-space>
        <a-row class="interviewQuestionListHome" justify="center">
          <a-col span="17">
            <!--  题库列表 -->
            <interview-question-table :search-params="searchParams" />
          </a-col>
          <a-col span="7" style="margin-top: 62.5px">
            <user-sider biz-type="question" />
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
  <!--  添加窗口 -->
  <a-modal v-model:visible="addVisible" :footer="false" draggable width="auto">
    <template #title> 添加题目</template>
    <div>
      <a-form-item label="标题">
        <a-input
          v-model="addData.title"
          placeholder="请输入标题..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="内容">
        <a-textarea
          v-model="addData.context"
          placeholder="请输入内容..."
          allow-clear
        />
      </a-form-item>
      <a-form-item label="答案">
        <MdEditor
          v-model:md-text="addData.answer"
          @on-change="(text) => (addData.answer = text)"
        />
      </a-form-item>
      <a-form-item label="标签">
        <TagSelect
          v-model="addData.tags"
          placeholder="可选5个标签，可搜索"
          :limit="5"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="addSubmit"> 添加</a-button>
      </a-form-item>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import API from "@/api";
import UserSider from "@/components/sider/UserSider.vue";

import InterviewQuestionTable from "@/components/list/InterviewQuestionTable.vue";
import InterviewQuestionBankPage from "@/views/interview/interviewQuestionBank/InterviewQuestionBankPage.vue";
import TagSelect from "@/components/selecter/TagSelecter.vue";
import MdEditor from "@/components/md/MdEditor.vue";
import { addInterviewQuestionUsingPost } from "@/request/api/interviewQuestionController";
import message from "@arco-design/web-vue/es/message";

//搜索参数
const searchParams = ref<API.InterviewQuestionQueryRequest>({
  current: 1,
  pageSize: 20,
  sortField: "createTime",
  sortOrder: "descend",
});

const addVisible = ref(false);
const addData = ref<API.InterviewQuestionAddRequest>({
  title: "",
  context: "",
  answer: "",
  tags: [],
  needVip: 0,
});
const addSubmit = async () => {
  const res = await addInterviewQuestionUsingPost(addData.value);
  if (res.data.code == 0) {
    addVisible.value = false;
    addData.value = {
      title: "",
      context: "",
      answer: "",
      tags: [],
      needVip: 0,
    };
    searchParams.value.current = 1;
    message.success("添加成功,请等待审核");
  }
};
</script>

<style scoped>
.interviewQuestionListHome {
  width: 1200px;
  margin: 0 auto;
}
</style>
