<template>
  <a-row class="postDetail" justify="center">
    <a-col span="16">
      <div class="post">
        <!--  帖子内容 -->
        <div class="postCart">
          <a-spin :loading="loading">
            <a-list-item class="postItem" action-layout="vertical">
              <template #actions>
                <span class="action">
                  <icon-eye />
                  {{ data.viewNum }}
                </span>
                <span class="action" @click="onLikeChange(data)">
                  <span v-if="data.isThumb">
                    <IconHeartFill :style="{ color: '#f53f3f' }" />
                  </span>
                  <span v-else>
                    <IconHeart />
                  </span>
                  {{ data.thumbNum }}
                </span>
                <span class="action" @click="onStarChange(data)">
                  <span v-if="data.isFavour">
                    <IconStarFill style="color: #fff35d" />
                  </span>
                  <span v-else>
                    <IconStar />
                  </span>
                  {{ data.favourNum }}
                </span>
                <span class="action">
                  <share-trigger />
                </span>
                <a-space size="medium">
                  <span v-if="isMy" class="action" @click="edit(data.id)">
                    <icon-edit />
                  </span>
                  <a-popconfirm
                    content="你确定要删除吗?"
                    @ok="remove(data.id)"
                    position="bottom"
                  >
                    <span v-if="isMy" class="action">
                      <icon-delete />
                    </span>
                  </a-popconfirm>
                </a-space>
              </template>

              <a-list-item-meta>
                <template #title>
                  <a-space>
                    <a-avatar :image-url="data.user?.userAvatar" />
                    <div>
                      <a-typography-text
                        style="font-size: 16px; font-weight: bold"
                      >
                        {{ data.user?.userName }}
                      </a-typography-text>
                      <br />
                      <a-typography-text style="color: #9f9f9f">
                        {{
                          dayjs(data.updateTime).format("YYYY-MM-DD HH:mm:ss")
                        }}
                      </a-typography-text>
                    </div>
                  </a-space>
                </template>
                <template #description>
                  <div @click="onClickMenuItem(`/post/detail/${data.id}`)">
                    <div v-show="data.title">
                      <a-typography-text
                        style="font-size: 20px; margin-left: 18px"
                      >
                        {{ data.title }}
                      </a-typography-text>
                    </div>
                    <md-viewer :value="data.content" />
                  </div>
                  <a-image-preview-group v-if="data.imgList?.length > 0">
                    <a-space size="large" style="margin: 10px 20px">
                      <a-image
                        v-for="item in data.imgList"
                        :key="item"
                        :src="item"
                        width="200"
                      />
                    </a-space>
                  </a-image-preview-group>
                  <br />
                  <div v-if="data.imgList?.length > 0">
                    <a-space style="margin: 10px 20px">
                      <a-tag v-for="item in data.tagList" :key="item">
                        {{ item }}
                      </a-tag>
                    </a-space>
                  </div>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </a-spin>
        </div>
        <!--  评论 -->
        <comment-cart
          class="postComment"
          biz-type="Post"
          :biz-id="props.id"
        ></comment-cart>
      </div>
    </a-col>
    <a-col span="6">
      <user-sider biz-type="post" />
    </a-col>
  </a-row>
</template>
<script setup lang="ts">
import { computed, defineProps, ref, watchEffect, withDefaults } from "vue";
import message from "@arco-design/web-vue/es/message";
import router from "@/router";
import {
  deletePostUsingPost,
  getPostVoByIdUsingGet,
} from "@/api/postController";
import dayjs from "dayjs";
import { useLoginUserStore } from "@/store/userStore";
import CommentCart from "@/components/list/CommentCart.vue";
import {
  doPostFavourUsingPost,
  doPostThumbUsingPost,
} from "@/request/api/postController";
import UserSider from "@/components/sider/UserSider.vue";
import MdViewer from "@/components/md/MdViewer.vue";
import ShareTrigger from "@/components/trigger/ShareTrigger.vue";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => {
    return 0;
  },
});

const data = ref<API.PostVO>({});
const loginUser = useLoginUserStore().loginUser;
const isMy = computed(() => {
  return loginUser.id && loginUser.id === data.value.userId;
});
const loading = ref(false);

/**
 * 加载数据
 */
const loadData = async () => {
  loading.value = true;
  const response = await getPostVoByIdUsingGet({ id: props.id });
  if (response.data.code === 0 && response.data.data) {
    data.value = response.data.data;
  } else {
    message.error("加载数据失败，" + response.data.message);
  }
  loading.value = false;
};

/**
 * 点赞
 */
const onLikeChange = (item: any) => {
  if (item.isThumb == true) {
    item.thumbNum--;
  } else {
    item.thumbNum++;
  }
  item.isThumb = !item.isThumb;
  doPostThumbUsingPost({ bizId: item.id, bizType: "Post" });
};

/**
 * 收藏
 */
const onStarChange = (item: any) => {
  if (item.isFavour == true) {
    item.favourNum--;
  } else {
    item.favourNum++;
  }
  item.isFavour = !item.isFavour;
  doPostFavourUsingPost({ bizId: item.id, bizType: "Post" });
};

/**
 * 删除帖子
 */
const remove = async (id: number) => {
  const response = await deletePostUsingPost({ id: id });
  if (response.data.code === 0) {
    message.success("删除成功");
    router.push("/");
  } else {
    message.error("删除失败，" + response.data.message);
  }
};
/**
 * 编辑帖子
 */
const edit = async (id: number) => {
  router.push("/post/edit/" + id);
};
/**
 * 点击菜单项
 * @param id
 */
const onClickMenuItem = async (url: string) => {
  router.push(url);
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
</script>
<style scoped>
.postDetail {
  min-width: 50%;
  max-width: 1400px;
  margin: 10px auto;
}

.postCart {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.postComment {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 1px 1px #e8e8e8;
}

.postItem {
  padding: 20px 30px;
}

.action {
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}

.list-action-layout .image-area {
  width: 183px;
  height: 119px;
  overflow: hidden;
  border-radius: 2px;
}

.list-action-layout .list-item {
  padding: 20px 0;
  border-bottom: 1px solid var(--color-fill-3);
}

.list-action-layout .image-area img {
  width: 100%;
}

.list-action-layout .arco-list-item-action .arco-icon {
  margin: 0 4px;
}
</style>
