<template>
  <a-row align="center" :wrap="false" justify="center" id="globalHeader">
    <a-col flex="100px" style="margin-left: 50px">
      <div @click="click('/')">
        <a-space>
          <img class="logo" src="../assets/logo.png" />
          <div class="title">易答</div>
        </a-space>
      </div>
    </a-col>
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item v-for="item in filterRouters" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="90px">
      <a-button
        type="text"
        @click="vipVisible = true"
        v-if="userStore.loginUser.id"
      >
        <a-space>
          <img src="../assets/vip.svg" style="width: 20px" />
          <a-typography-text>会员</a-typography-text>
        </a-space>
      </a-button>
    </a-col>
    <a-col flex="200px">
      <a-space>
        <div v-if="userStore.loginUser.id">
          <a-space>
            <a-avatar
              :image-url="userStore.loginUser.userAvatar"
              @click="click('/my/user')"
            />
            <a-dropdown @select="handleSelect" :popup-max-height="true">
              <a-button>
                <span class="userName">
                  {{ userStore.loginUser.userName ?? "无名" }}
                </span>
                <icon-down />
              </a-button>
              <template #content>
                <a-doption>
                  <a-link @click="click('/my/user')"> 我的信息</a-link>
                </a-doption>
                <a-doption>
                  <a-link @click="click('/my/favour/interviewQuestion')">
                    收藏题目
                  </a-link>
                </a-doption>
                <a-doption key="logout" @click="loginOut"> 退出登录</a-doption>
              </template>
            </a-dropdown>
          </a-space>
        </div>
        <div v-else style="margin-left: 100px">
          <a-button type="primary" @click="click('/user/login')"
            >登录
          </a-button>
        </div>
      </a-space>
    </a-col>
    <a-modal v-model:visible="vipVisible" hide-cancel>
      <template #title> 会员中心</template>
      <vip-window style="margin-left: 90px" />
    </a-modal>
  </a-row>
</template>
<script setup lang="ts">
import { routes } from "@/router";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import checkAccess from "@/access/checkAccess";
import { userLogoutUsingPost } from "@/api/userController";
import VipWindow from "@/components/VipWindow.vue";

const router = useRouter();
const userStore = useLoginUserStore();

/**
 * 过滤菜单
 */
const filterRouters = computed(() => {
  return routes.filter((item) => {
    // 根据权限过滤菜单
    if (!checkAccess(userStore.loginUser, item.meta?.access as string)) {
      return false;
    }
    // 根据隐藏过滤菜单
    if (item.meta?.showInMenu == true) {
      return true;
    }
    return false;
  });
});
/**
 * 点击菜单跳转
 */
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

const click = (url: string) => {
  router.push({ path: url });
};
/**
 * 路由跳转后更新选中菜单项
 */
const selectKeys = ref([""]);
router.afterEach((to) => {
  selectKeys.value = [to.path];
});

/**
 * 登出
 */
function loginOut() {
  userLogoutUsingPost({ id: userStore.loginUser.id }).then(() => {
    userStore.loginUser = {};
  });
  router.go(0);
}

/**
 * 点击下拉菜单
 */
function handleSelect(key: string) {
  if (key === "logout") {
    loginOut();
  }
}

const vipVisible = ref(false);
</script>
<style scoped>
#globalHeader {
  margin: 0 auto;
}

.userName {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  margin-left: 16px;
  color: black;
  font-size: 18px;
}

.logo {
  width: 40px;
  padding: 10px 0;
}
</style>
